import { cartAPI } from '@backmarket/http-api'
import { defineStore } from 'pinia'

import { FORM_TYPES } from '../types'

interface State {
  birthdate: string
  formType: (typeof FORM_TYPES)[keyof typeof FORM_TYPES]
  missingData: boolean
  nationalId: string | null
}

export const useUserInformationStore = defineStore('userInformationStore', {
  state: (): State => ({
    birthdate: '',
    formType: FORM_TYPES.NONE,
    missingData: false,
    nationalId: '',
  }),
  getters: {
    // If the nationalId comes from the cart request as null or with a value it means it's required.
    // If the key does not come at all, it means it's not required
    // It is also returning a function since we don't want to cache the result of it.
    isNationalIdRequired: (state) => () => {
      return 'nationalId' in state
    },
    isFormRequired: (state) => state.formType !== FORM_TYPES.NONE,
  },
  actions: {
    setUserInformation(
      userInformation: cartAPI.UserInformation = {
        birthdate: '',
        formType: FORM_TYPES.NONE,
        missingData: false,
        taxId: undefined,
      },
    ) {
      const {
        birthdate,
        formType,
        missingData,
        taxId: nationalId,
      } = userInformation

      this.birthdate = birthdate || ''
      this.formType = formType
      this.missingData = missingData

      if (nationalId !== undefined) {
        this.nationalId = nationalId || ''
      } else {
        this.nationalId = null
      }
    },
  },
})
